import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import designStudioService from '../../../../services/api/design-studio';
import type { Project } from '../../../../services/api/design-studio/types';
import Details from './Details';

const CreateProject: FC = () => {
  const navigate = useNavigate();

  const initialProject: Partial<Project> = {
    projectName: '',
    description: '',
    propertyName: '',
    product: '',
    reference: '',
  };

  const handleSave = async (values: Partial<Project>) => {
    try {
      const formattedData = {
        name: values.name || '',
        propertyName: values.propertyName || '', // @TODO: fix this to be a string
        description: values.description || '',
        propertyCode: values.propertyCode || '',
        projectLeadId: values.projectLeadId || '1',
        designStatusId: values.designStatusId || '1',

        financialStatusId: '550e8400-e29b-41d4-a716-446655440015', // @TODO: fix this to be a status or remove from API
        regionId: values.region?.id || '1',
        startDate: new Date().toISOString().split('T')[0], // @TODO: fix this to be a date
        projectValue: values.projectValue || 0, // @TODO: fix this to be a numbe
        localCurrencyId: '550e8400-e29b-41d4-a716-446655440006', // @TODO: fix this to be a currency or remove from API
        region: '550e8400-e29b-41d4-a716-446655440010', // @TODO: fix this to be a region or remove from API
      };

      // @ts-expect-error - this has to be fixed when api will change
      await designStudioService.createProject(formattedData);
      navigate('/design-studio/projects/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Details
        project={initialProject as Project}
        onSave={handleSave}
        onCancel={() => navigate(-1)}
      />
    </>
  );
};

export default CreateProject;
