import MaterialIcon from '@material/react-material-icon';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState, type FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_Cell,
} from 'material-react-table';
import generateCsvContent from '../../../helpers/generateCSVData';
import { setVisibleColumns } from '../../../store/columnsVisibility/columnsVisibilitySlice';
import { type RootState } from '../../../store/types';
import { setAuthToken } from '../../../services/api';
import designStudioService from '../../../services/api/design-studio';
import TooltipButton from '../components/TooltipButton';

interface Address {
  id: string;
  address: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
  };
  isPrimary: boolean;
}

interface Region {
  id: string;
  code: string;
  name: string;
}

interface Status {
  id: string;
  code: string;
  name: string;
  color?: string;
}

interface ProjectLead {
  id: string;
  code: string;
  name: string;
  email: string;
}

interface Currency {
  id: string;
  code: string;
  name: string;
  symbol: string;
  exchangeRate: number;
}

interface Project {
  id: string;
  propertyName: string;
  product: string;
  projectName: string;
  description: string;
  reference: string;
  projectValue: number;
  startDate: string;
  completionDate: string;
  workdayProjectReference: string;
  region: Region;
  designStatus: Status;
  financialStatus: Status;
  projectLead: ProjectLead;
  currency: Currency;
  addresses: Address[];
}

interface DateCellProps {
  cell: MRT_Cell<Project>;
}

const DateCell: FC<DateCellProps> = ({ cell }) => {
  const value = cell.getValue<string>();
  return value ? new Date(value).toLocaleDateString() : '-';
};

interface ProjectValueCellProps {
  row: MRT_Row<Project>;
}

const ProjectValueCell: FC<ProjectValueCellProps> = ({ row }) => {
  const value = row.original.projectValue;
  const { symbol } = row.original.currency;
  return value ? `${symbol}${value.toLocaleString()}` : '-';
};

// Column definitions
const columns: MRT_ColumnDef<Project>[] = [
  {
    accessorKey: 'reference',
    header: 'Reference',
    size: 120,
  },
  {
    accessorKey: 'projectName',
    header: 'Project Name',
    size: 200,
  },
  {
    accessorKey: 'propertyName',
    header: 'Property Name',
    size: 200,
  },
  {
    accessorKey: 'product',
    header: 'Product Type',
    size: 150,
  },
  {
    accessorKey: 'region.name',
    header: 'Region',
    size: 150,
  },
  {
    accessorKey: 'projectLead.name',
    header: 'Project Lead',
    size: 150,
  },
  {
    accessorKey: 'designStatus.name',
    header: 'Design Status',
    size: 130,
  },
  {
    accessorKey: 'financialStatus.name',
    header: 'Financial Status',
    size: 130,
  },
  {
    accessorKey: 'startDate',
    header: 'Start Date',
    Cell: ({ cell }: { cell: MRT_Cell<Project> }) => <DateCell cell={cell} />,
    size: 120,
  },
  {
    accessorKey: 'completionDate',
    header: 'Completion Date',
    Cell: ({ cell }: { cell: MRT_Cell<Project> }) => <DateCell cell={cell} />,
    size: 120,
  },
  {
    accessorKey: 'projectValue',
    header: 'Project Value',
    Cell: ({ row }: { row: MRT_Row<Project> }) => <ProjectValueCell row={row} />,
    size: 150,
  },
  {
    accessorKey: 'workdayProjectReference',
    header: 'Workday Ref',
    size: 120,
  },
];

const useColumnVisibility = (name: keyof RootState['columnsVisibility']) => {
  const dispatch = useDispatch();
  const visibleColumns = useSelector((state: RootState) => state.columnsVisibility[name]);
  const [columnVisibility, setColumnVisibilityState] = useState(visibleColumns);

  useEffect(() => {
    dispatch(setVisibleColumns({ name, columnVisibility }));
  }, [columnVisibility, dispatch, name]);

  return {
    columnVisibility,
    setColumnVisibility: setColumnVisibilityState,
  };
};

// Main Component
const ProjectsTable: FC = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const response = await designStudioService.getProjects();
      setProjects(response.data.data);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!authState?.idToken?.idToken) return;

    setAuthToken(authState.idToken.idToken);
    fetchProjects();
  }, [authState?.idToken?.idToken]);

  // Memoize the table data to prevent unnecessary re-renders
  const tableData = useMemo(() => projects || [], [projects]);

  const { columnVisibility, setColumnVisibility } = useColumnVisibility('projects');

  const handleAddNew = () => {
    navigate('/design-studio/projects/new');
  };

  const handleRowClick = (project: Project) => {
    navigate(`/design-studio/projects/${project.id}`, {
      state: { project },
    });
  };

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading projects</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={fetchProjects}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="projects">
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        onColumnVisibilityChange={setColumnVisibility}
        initialState={{
          density: 'compact',
          showColumnFilters: true,
          sorting: [{ id: 'reference', desc: false }],
        }}
        state={{
          columnVisibility,
          isLoading,
          showSkeletons: isLoading,
          showProgressBars: isLoading,
        }}
        muiTableContainerProps={{
          sx: {
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            maskImage: 'linear-gradient(to left, transparent, black 40px)',
            WebkitMaskImage: 'linear-gradient(to left, transparent, black 40px)',
          },
        }}
        muiTablePaperProps={{
          sx: {
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '40px',
              background: 'linear-gradient(to right, transparent, white)',
              pointerEvents: 'none',
              zIndex: 1000,
            },
          },
        }}
        muiTableBodyCellProps={({ row }) => ({
          onDoubleClick: () => handleRowClick(row.original),
          sx: { cursor: 'pointer' },
        })}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          sx: {
            display: isLoading ? 'block' : 'none',
            position: 'absolute',
            top: isTopToolbar ? 0 : 'auto',
            bottom: isTopToolbar ? 'auto' : 0,
            width: '100%',
          },
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="m-1 d-flex w-100 justify-content-between">
            <TooltipButton
              id="downloadItems"
              onClick={() => {
                generateCsvContent(
                  table.getPrePaginationRowModel().rows,
                  table.getFlatHeaders(),
                  'Projects',
                  true,
                );
              }}
              tooltipContent="Download Projects CSV"
            >
              <MaterialIcon icon="download" />
            </TooltipButton>
            <TooltipButton
              id="addNewProject"
              tooltipContent="Add a new Project"
              onClick={handleAddNew}
            >
              <MaterialIcon icon="add_circle" style={{ marginRight: '5px' }} />
              Add project
            </TooltipButton>{' '}
          </div>
        )}
      />
    </div>
  );
};

export default ProjectsTable;
