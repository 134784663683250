import api from '../api';
import type { Project } from './design-studio/types';

const designStudioService = {
  getProjects: () => api.get('/design-studio/projects'),
  createProject: (data: Partial<Project>) => api.post('/design-studio/projects', data),
  updateProject: (id: string, data: Partial<Project>) =>
    api.patch(`/design-studio/projects/${id}`, data),
  getProjectAddresses: (id: string) => api.get(`/design-studio/projects/${id}/addresses`),
  getDesignStatuses: () => api.get('/design-studio/ref/design-status'),
  getProperties: () => api.get('/design-studio/ref/property'),
  getProject: (id: string) => api.get(`/design-studio/projects/${id}`),
  getProjectContacts: (id: string) => api.get(`/design-studio/projects/${id}/contacts`),
  getFinancialStatuses: () => api.get('/design-studio/ref/financial-status'),
  getLeads() {
    return api.get('/design-studio/ref/leads');
  },
};

export default designStudioService;
