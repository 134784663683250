import { useEffect, useState, type FC, useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import MaterialIcon from '@material/react-material-icon';
import designStudioService from '../../../services/api/design-studio';
import TooltipButton from '../components/TooltipButton';
import generateCsvContent from '../../../helpers/generateCSVData'; // Assuming you have a helper for CSV generation

interface Address {
  id: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  isPrimary: boolean;
}

interface AddressesProps {
  projectId: string;
}

const columns: MRT_ColumnDef<Address>[] = [
  {
    accessorKey: 'type.name',
    header: 'Address Type',
    size: 200,
  },
  {
    accessorKey: 'address.streetAddress',
    header: 'Street Address',
    size: 200,
  },
  {
    accessorKey: 'address.city',
    header: 'City',
    size: 150,
  },
  {
    accessorKey: 'address.state',
    header: 'State',
    size: 150,
  },
  {
    accessorKey: 'address.postalCode',
    header: 'Postal Code',
    size: 100,
  },
];

const Addresses: FC<AddressesProps> = ({ projectId }) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAddresses = async () => {
    try {
      setIsLoading(true);
      const response = await designStudioService.getProjectAddresses(projectId);
      setAddresses(response.data.data);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, [projectId]);

  const tableData = useMemo(() => addresses || [], [addresses]);

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading addresses</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={fetchAddresses}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        initialState={{
          density: 'compact',
          showColumnFilters: true,
          sorting: [{ id: 'streetAddress', desc: false }],
        }}
        state={{
          isLoading,
          showSkeletons: isLoading,
          showProgressBars: isLoading,
        }}
        muiTableContainerProps={{
          sx: {
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="m-1 d-flex w-100 justify-content-between">
            <TooltipButton
              id="downloadItems"
              onClick={() => {
                generateCsvContent(
                  table.getPrePaginationRowModel().rows,
                  table.getFlatHeaders(),
                  'Projects',
                  true,
                );
              }}
              tooltipContent="Download Projects CSV"
            >
              <MaterialIcon icon="download" />
            </TooltipButton>
            <TooltipButton id="addNewContact" tooltipContent="Add a new Contact">
              <MaterialIcon icon="add_circle" style={{ marginRight: '5px' }} />
              Add
            </TooltipButton>
          </div>
        )}
      />
    </div>
  );
};

export default Addresses;
