import type { FC } from 'react';
import { Button } from 'reactstrap';
import type { Message } from '../../../services/api/design-studio/types';

type FooterActionBarProps = {
  onCancel?: (() => void) | undefined;
  message: Message;
};

const FooterActionbar: FC<FooterActionBarProps> = ({ onCancel, message }) => {
  return (
    <div className={`footerActionBar ${message.type}`}>
      <div className="footerActionBarButtonsContainer">
        {message.type ? (
          <div>{message.text}</div>
        ) : (
          <>
            <button type="button" className="footerActionBarCancelButton" onClick={onCancel}>
              Cancel
            </button>
            <Button type="submit" style={{ marginLeft: 20, padding: '5px 19px' }} color="primary">
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default FooterActionbar;
