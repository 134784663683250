import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useOktaAuth } from '@okta/okta-react';
import Details from './project-detail-screens/Details';
import type { Project } from '../../../services/api/design-studio/types';
import Items from '../items/Items';
import Contacts from '../contacts/Contacts';
import Addresses from '../addresses/Addresses';
import Rfqs from '../rfqs/Rfqs';
import BudgetView from '../budget/BudgetView';
import designStudioService from '../../../services/api/design-studio';
import CreateProject from './project-detail-screens/CreateProject';
import { setAuthToken } from '../../../services/api';
import SelectArrowLeft from '../../../assets/images/icons/SelectArrowLeft.svg';
import '../styles.css';

const ProjectView = () => {
  const [activeScreen, setActiveScreen] = useState('details');
  const [project, setProject] = useState<Project | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { authState } = useOktaAuth();

  const fetchProject = useCallback(async () => {
    try {
      if (id && id !== 'new') {
        const { data } = await designStudioService.getProject(id);
        setProject(data.data);
      }
    } catch (error) {
      console.error('Failed to fetch project:', error);
      navigate('/design-studio/projects');
    }
  }, [id]);

  useEffect(() => {
    if (!authState?.idToken?.idToken) return;
    setAuthToken(authState.idToken.idToken);
    fetchProject();
  }, [id, navigate]);

  if (id === 'new') {
    return <CreateProject />;
  }

  if (!project) {
    return <div>Loading...</div>;
  }

  const handleSave = async (updatedProject: Partial<Project>) => {
    try {
      await designStudioService.updateProject(project.id, updatedProject);
      await fetchProject();
    } catch (error) {
      console.error('Failed to save project:', error);
      throw error;
    }
  };

  const nav = [
    { href: 'details', title: 'Details' },
    { href: 'items', title: 'Items' },
    { href: 'rfqs', title: 'RFQs' },
    { href: 'contacts', title: 'Contacts' },
    { href: 'addresses', title: 'Addresses' },
    { href: 'budget', title: 'Budget' },
  ];

  const renderContent = () => {
    switch (activeScreen) {
      case 'details':
        return <Details project={project} onSave={handleSave} />;
      case 'budget':
        return <BudgetView />;
      case 'items':
        return <Items />;
      case 'rfqs':
        return <Rfqs />;
      case 'contacts':
        return <Contacts projectId={project.id} />;
      case 'addresses':
        return <Addresses projectId={project.id} />;
      default:
        return <Details project={project} />;
    }
  };

  return (
    <div className="w-100 bg-white" style={{ height: 'calc(100vh - 118px)' }}>
      <Container fluid className="h-100">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="2">
              <button type="button" className="backToProjectsButton" onClick={() => navigate(-1)}>
                <img src={SelectArrowLeft} alt="Back to projects" />
                <div className="d-none d-md-flex">Projects</div>
              </button>
            </Col>
            <Col md="9" className="text-center">
              <h5 className="mb-4">{project.projectName}</h5>
            </Col>
            <Col md="1"></Col>
          </Row>
        </Container>

        <SimpleBar style={{ borderBottom: '1px solid #DFD6C4' }}>
          <Nav className="justify-content-center p-2">
            {nav.map((item) => (
              <NavItem key={item.href} className="mx-2">
                <NavLink
                  onClick={() => setActiveScreen(item.href)}
                  className="px-3 py-1 rounded-pill"
                  style={{
                    backgroundColor: activeScreen === item.href ? 'var(--bs-primary)' : 'white',
                    color: activeScreen === item.href ? 'white' : 'var(--bs-primary)',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                >
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </SimpleBar>

        <div className="py-4">{renderContent()}</div>
      </Container>
    </div>
  );
};

export default ProjectView;
