import { useEffect, useState, type FC } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import MaterialIcon from '@material/react-material-icon';
import designStudioService from '../../../services/api/design-studio';
import generateCsvContent from '../../../helpers/generateCSVData';
import TooltipButton from '../components/TooltipButton'; // Assuming you have a helper for CSV generation

interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  company: string;
  isActive: boolean;
}

interface ContactsProps {
  projectId: string;
}

const columns: MRT_ColumnDef<Contact>[] = [
  {
    accessorKey: 'contact.firstName',
    header: 'First Name',
    size: 150,
  },
  {
    accessorKey: 'contact.lastName',
    header: 'Last Name',
    size: 150,
  },
  {
    accessorKey: 'contact.email',
    header: 'Email',
    size: 200,
  },
  {
    accessorKey: 'contact.phone',
    header: 'Phone',
    size: 150,
  },
  {
    accessorKey: 'contact.role',
    header: 'Role',
    size: 150,
  },
  {
    accessorKey: 'contact.department',
    header: 'Department',
    size: 200,
  },
];

const Contacts: FC<ContactsProps> = ({ projectId }) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchContacts = async () => {
    try {
      setIsLoading(true);
      const response = await designStudioService.getProjectContacts(projectId);
      setContacts(response.data.data);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [projectId]);

  if (error) {
    return (
      <div className="error-container p-4">
        <h2 className="text-red-600">Error loading contacts</h2>
        <p>{error.message}</p>
        <button
          type="button"
          onClick={fetchContacts}
          className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={contacts}
        enableFilters
        enableColumnFilterModes
        enableColumnOrdering
        initialState={{
          density: 'compact',
          showColumnFilters: true,
        }}
        state={{
          isLoading,
          showSkeletons: isLoading,
          showProgressBars: isLoading,
        }}
        muiTableContainerProps={{
          sx: {
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            maskImage: 'linear-gradient(to left, transparent, black 40px)',
            WebkitMaskImage: 'linear-gradient(to left, transparent, black 40px)',
          },
        }}
        muiTablePaperProps={{
          sx: {
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: '40px',
              background: 'linear-gradient(to right, transparent, white)',
              pointerEvents: 'none',
              zIndex: 1000,
            },
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <div className="m-1 d-flex w-100 justify-content-between">
            <TooltipButton
              id="downloadItems"
              onClick={() => {
                generateCsvContent(
                  table.getPrePaginationRowModel().rows,
                  table.getFlatHeaders(),
                  'Projects',
                  true,
                );
              }}
              tooltipContent="Download Projects CSV"
            >
              <MaterialIcon icon="download" />
            </TooltipButton>
            <TooltipButton id="addNewContact" tooltipContent="Add a new Contact">
              <MaterialIcon icon="add_circle" style={{ marginRight: '5px' }} />
              Add
            </TooltipButton>
          </div>
        )}
      />
    </div>
  );
};

export default Contacts;
